
import { defineComponent, onMounted } from "vue";
import ClientClaimsTable from "@/components/individual/ClientClaimsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "underwriter-client-settled-claims-listing",
  components: { ClientClaimsTable },
  data() {
    return {
      claimsSummary: {},
      claims: []
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Settled Claims", ["Clients", "Claims"]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    // Get client filed claims
    this.getClientsClaims();

    //Set page title
    document.title = "Clients Settled Claims | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClientsClaims() {
      //Fetch client filed claims
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/underwriter/settled-claims")
          .then(({ data }) => {
            //Assign data to props
            this.claimsSummary = data.data;
            this.claims = data.data;
          })
          .catch(function (error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  }
});
